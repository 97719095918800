import { TagItem } from "./CBModels";

export class TagItemParser {
    static ParseTagItem(tstr: string, pass_i = { i : 0 }, closing = "}}") : TagItem {
        let i = pass_i.i;
        let ret = { 
            type: "{",
            items: []
        } as TagItem;
        let section = {
            type: ",",
            items: []
        } as TagItem;
        ret.items?.push(section);
        let prov_term = null as TagItem | null;
        while(i < tstr.length) {
            while(i < tstr.length && (tstr[i] == ' ' || tstr[i] == '\n' || tstr[i] == '\r'  || tstr[i] == '*' )) {

                if(tstr[i] == '*' && ret.items?.length == 1 && ret.items[0].items?.length == 0  && ret.type.length == 1) {
                    ret.type += "*"; //volatile
                }

                i++;
            }
            let is_end = true;
            for(let e = 0; e < closing.length; e++) {
                if(tstr[i + e] != closing[e]) {
                    is_end = false;
                    break;
                }
            }
            if(is_end) {
                i += closing.length;

                if(section.items) {
                    if(section.items.length == 0 || section.items[section.items?.length-1].type == "?" || section.items[section.items?.length-1].type == "~") {
                        //This is ,}} or ?}}
                        section.items?.push({ type: 's', slice: "", val: "" });
                    }
                }

                break;
            }

            if(tstr[i] == "(") {
                i++;
                let pi = { i : i };
                let subitem = TagItemParser.ParseTagItem(tstr, pi, ")");
                section.items?.push(subitem);  
                i = pi.i;
            }
            else if(tstr[i] == ',') {
                if(section && section.items && (section.items.length == 0 || section.items[section.items?.length-1].type == "?" || section.items[section.items?.length-1].type == "~")) {
                    //This is ,}} or ?}}
                    section.items?.push({ type: 's', slice: "", val: "" });
                }
                section = {
                    type: ",",
                    items: []
                } as TagItem;
                ret.items?.push(section);
                i++;
            }
            else if(tstr[i] == ';') {
                let opening = "{";
                if(closing == ")") {
                    opening = "(";
                }
                i++;
                //skip whitespace
                while(i < tstr.length && (tstr[i] == ' ' || tstr[i] == '\n' || tstr[i] == '\r')) {
                    i++;
                }

                let format = TagItemParser.ReadBlock(tstr, i, opening);

                let section = { type: ';', slice: format};
                ret.items?.push(section);
                i += format.length;
            }                                                                                                                                                                                                                               //- preceded by number or p mean subtract, otherwose it is negate, will be picket up by number below.                            
            else if(tstr[i] == '=' || tstr[i] == '!' || tstr[i] == '<' || tstr[i] == '>' || tstr[i] == '?' || tstr[i] == '~' || tstr[i] == ':' || tstr[i] == '+' || tstr[i] == '|' || tstr[i] == '&' || tstr[i] == '(' || tstr[i] == ')' || (tstr[i] == '-' && (prov_term != null && (prov_term.type == "p" || prov_term.type == "n")))) {

                let t = tstr[i];
    
                let added = false;
    
                if(prov_term) {
                    if(t == '=') {
                        if(prov_term.type == '=') {   
                            added = true; //= -> ==             
                        }
                        else if(prov_term.type == '!') {  
                            added = true; //! -> !=
                        }
                        else if(prov_term.type == "<") {
                            prov_term.type = "<=";
                            added = true;
                        }
                        else if(prov_term.type == ">") {
                            prov_term.type = ">=";
                            added = true;
                        }
                    }
                    else if(t == '|' && prov_term.type == '|') {
                        added = true; //| -> ||     
                    }
                    else if(t == '&' && prov_term.type == '&') {
                        added = true; //& -> &&     
                    }

                }
    
                if(!added) {
                    prov_term = { type: t };
                    section.items?.push(prov_term);
                }
    
                i++;
            }
            else if(tstr[i] == "'" || tstr[i] == '"') {
                let t = tstr[i];
                let ti = i+1;
                i++;
                while(i < tstr.length && tstr[i] != t) {
                    i++;
                }
    
                let val = tstr.substring(ti, i);
    
                prov_term = { type: 's', slice: val, val: val }; 
                section.items?.push(prov_term);
    
                i++;
            }
            else if((tstr[i] >= '0' && tstr[i] <= '9') || tstr[i] == '-') {
                let ti = i;
                i++;
                while(i < tstr.length && tstr[i] >= '0' && tstr[i] <= '9') {
                    i++;
                }
    
                let slice = tstr.substring(ti, i)
                let val = Number(slice);
                if(isNaN(val)) {
                    ret.type = "error";
                    ret.slice = "Invalid number "+tstr.substring(ti, i)+" at position " + i + " in " + tstr;
                    return ret;
                }
    
                prov_term = { type: 'n', slice: slice, val: val };
                section.items?.push(prov_term);
            }
            else if(i < tstr.length && tstr[i] != '}') {
                let ti = i;
                i++;
                //skip to end of word
                let wstate = "";

                while(i < tstr.length && 
                    ((tstr[i] != '}' && tstr[i] != '=' && tstr[i] != '!' && tstr[i] != '>' && tstr[i] != '<' && tstr[i] != '?' && tstr[i] != '~' && tstr[i] != ':' && tstr[i] != '+' && tstr[i] != '-' && tstr[i] != '&' && tstr[i] != '|' && tstr[i] != ')' && tstr[i] != ',' && tstr[i] != ';' && tstr[i] != '\n' && tstr[i] != '\r' && tstr[i] != ' ')
                    || wstate == "[")) {

                    if(tstr[i] == "[") {
                        wstate = "[";
                    }
                    if(tstr[i] == "]") {
                        wstate = "";
                    }
                    i++;
                }
    
                let val = tstr.substring(ti, i);
    
                prov_term = { type: 'p', slice: val, val: undefined };
                if(val == "true" || val == "false") {
                    prov_term.type = 'b';
                    prov_term.slice = val;
                    prov_term.val = prov_term.slice == "true";
                }
                else if(val == "null") {
                    prov_term.type = '0';
                    prov_term.slice = val;
                    prov_term.val = null;
                }

                section.items?.push(prov_term);
            }
            else {
                i++;//If none matched due to malformed tag, don't inf loop
            }
        }
        pass_i.i = i;
        return ret;
    }


    static ReadBlock(str: string, pos: number, block_open: string) {
        let open_close_obj = {} as  any;
        open_close_obj["'"] =  { close: "'" , allow_sub: false };
        open_close_obj['"'] =  { close: '"' , allow_sub: false };
        open_close_obj["("] =  { close: ")" , allow_sub: true };
        open_close_obj["{"] =  { close: "}" , allow_sub: true };

        let stack = [block_open];

        for(let i = pos; i < str.length; i++) {
            let state = open_close_obj[stack[stack.length - 1]];
            if(str[i] == state.close) {
                stack.pop();
                if(stack.length == 0) {
                    return str.substring(pos, i);
                }
            }
            else if(state.allow_sub) {
                let oc = open_close_obj[str[i]];
                if(oc) {
                    stack.push(str[i]);
                }
            }
        }
        return str.substring(pos);
    }
}